<template>
  <el-select
    v-model="syncModel"
    class="available-time-select"
    no-data-text="暫無數據"
    placeholder="請選擇"
    :disabled="disabled"
    @change="$emit('change', syncModel)"
  >
    <el-option
      v-for="(item, index) in timesList"
      :key="index"
      :data-testid="`period-${dateFormat(item)}`"
      :label="dateFormat(item)"
      :value="item"
    />
  </el-select>
</template>

<script>
import dayjs from '@/lib/dayjs'
import { isPeriodTimeFormat } from '@/utils/date'
export default {
  name: 'AvailableTimeSelect',
  props: {
    model: {
      type: [Date, String],
      required: true,
    },
    timesList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },
  },
  methods: {
    dateFormat (date) {
      if (isPeriodTimeFormat(date)) return date
      return dayjs(date).format('HH:mm')
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
